import LogRocket from 'logrocket';
import {isStage, mobileCheck} from './src/utils';
import {availableLanguages, defaultLang} from './src/locales/constants';

import './src/global.css';

mobileCheck();

const isHeadlessChrome = () => {
    const userAgent = navigator.userAgent;

    return userAgent.includes('HeadlessChrome');
}

if (!isStage && !isHeadlessChrome()) {
    LogRocket.init('8lugdu/gil-landing')
}


export const onClientEntry = () => {
    window.appInfo = {
        ...(window?.appInfo || {}),
        APP_VERSION: process.env.GATSBY_APP_VERSION,
    };

    const currentPath = window.location.pathname;

    const storageLang = localStorage.getItem('gatsby-i18next-language');
    const languageCode = currentPath.split('/')[1];
    const navigatorLang = navigator.language || navigator.userLanguage;
    const userLang = languageCode || storageLang || navigatorLang; // Detect browser language

    // Check if the detected language starts with one of the supported languages
    const lang = availableLanguages.find(l => userLang.startsWith(l)) || storageLang;

    if (lang === defaultLang) {
        localStorage.removeItem('gatsby-i18next-language');

        return;
    }

    if (availableLanguages.includes(lang)) {
        localStorage.setItem('gatsby-i18next-language', lang);
    }

  if (!currentPath.startsWith(`/${lang}`) && lang) {
    window.location.href = `/${lang}${currentPath}`;
  }

  // set isMobile className
  mobileCheck();

};

export const onRouteUpdate = () => {
  window.scrollTo(0, 0);
};